.App {
  text-align: inherit; 
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.toolbar {
  background-color: #fff;
  box-shadow: 0 0 12px grey;
  z-index: 100;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  pointer-events: auto
}

.toolbarBottom,.toolbarTop {
  border-radius: 24px;
  padding-left: 24px;
  padding-right: 24px;
  height: 48px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: auto;
  box-sizing: border-box;
}

.toolbarLeft,.toolbarRight {
  border-radius: 24px;
  padding-top: 24px;
  padding-bottom: 24px
}

.toolbar i {
  color: #000;
  font-size: 16px
}

@media (max-width: 480px) {
  .toolbarBottom {
    border-radius:0;
    padding-left: 0;
    padding-right: 0;
    width: 100%
  }
}

@media (max-width: 384px) {
  #toolbarButton4 {
    display:none
  }
}

@media (max-width: 336px) {
  #toolbarButton3 {
    display:none
  }
}

@media (max-width: 288px) {
  #toolbarButton2 {
    display:none
  }
}


@media screen and (-ms-high-contrast:active) {
  .toolbarTop {
    border-radius: 0;
    padding-left: 0;
    padding-right: 0
  }

  .toolbarLeft,.toolbarRight {
    border-radius: 0;
    padding-top: 0;
    padding-bottom: 0
  }
}

.toolbarButton {
  width: 48px;
  height: 48px;
  min-width: 48px;
  padding: 0;
  margin: 0;
  border: none;
  position: relative;
  color: rgb(32, 31, 30);
  user-select: none;
  outline: transparent;
  text-decoration: none;
  border-radius: 2px;
}

.toolbarButtonTooltipHost {
  max-width: 48px;
  max-height: 48px
}

.toolbarButtonLeft {
  transform: rotate(90deg)
}

.toolbarButtonRight {
  transform: rotate(-90deg)
}

.unselectedToolbarButton {
  background-color: #fff
}

.unselectedToolbarButton:hover {
  background-color: rgba(0,0,0,.1)
}

.unselectedToolbarButton:active {
  background-color: rgba(0,0,0,.2)
}

.selectedToolbarButton {
  background-color: rgba(0,0,0,.1)
}

.selectedToolbarButton:hover {
  background-color: rgba(0,0,0,.2)
}

.selectedToolbarButton:active {
  background-color: rgba(0,0,0,.3)
}

@media screen and (-ms-high-contrast:active) {
  .toolbarButton {
    border: solid
  }

  .toolbarButton:focus {
    outline: 1px inset;
    outline-offset: -6px
  }

  .selectedToolbarButton span {
    background-color: highlight;
    -ms-high-contrast-adjust: none
  }

  .selectedToolbarButton:focus {
    outline-color: highlightText;
    -ms-high-contrast-adjust: none
  }

  .selectedToolbarButton i {
    color: highlightText
  }
}


.chrome-Button {
  width: 48px;
  height: 48px;
  background-color: #fff;
  outline-style: none;
}

.chrome-Button:hover {
  background-color: rgba(0, 0, 0, .1);
}

.chrome-Button.activePen:hover {
  background-color: rgba(0, 0, 0, .2);
}

button {
  padding: 0 10px;
  color: #000;
  height: 32px;
  border: none;
  text-align: center;
  font-size: 12px;
  /*white-space: nowrap*/
}


.pen-0 {
  fill: none;
}

.pen-1 {
  fill: #e6e6e6;
}

.pen-2 {
  fill: url(#pen-linear-gradient);
}

.pen-3 {
  fill: url(#pen-linear-gradient-2);
}

.chrome {
  display: flex;
  position: absolute;
  z-index: 100;
  pointer-events: none
}

.chromeTop {
  top: 16px;
  left: 16px;
  right: 16px;
  justify-content: space-between
}

body {
  margin: 0;
  padding: 0;
  background: #ccc;
  font-family: Segoe UI,SegoeUI,Helvetica Neue,Helvetica,Arial,sans-serif;
}

#enablePanzoom .enablePanzoomIcon {
  width: 16px;
  height: 16px;
  padding: 16px;
  vertical-align: top;
}

svg {
  margin: auto;
}

svg:not(:root) {
  overflow: hidden;
}

.eraser-0 {
  fill: none;
}

.eraser-1 {
  fill: #ed99c9;
}

.eraser-2 {
  fill: #ffc114;
}

.eraser-3 {
  fill: #e6e6e6;
}

.eraser-4 {
  fill: url(#barrel_highlight_1_);
}

.eraser-5 {
  fill: url(#metal_highlight_1_);
}

.activePen {
  background-color: rgba(0, 0, 0, .1);
}

#svgParent {
  position: absolute;
  width: 100%;
  height: 100%;
}
.flexAlignCenter {
  display: flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
}

#readOnlyTag, .wbdA11Y, .wbdA11y {
  position: absolute;
}

#svg {
  width: auto;
  height: auto;
  background: #fff;
}

.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}

#footer {
  position: fixed;
  height: auto;
  bottom: 0;
  background: #fff;
  width: 100%;
}

.eraseActive,.eraseActive * {
  cursor: url(resources/EraserCursor.cur),default
}

.toolbarText {
  font-size: 15px;
  font-family: Segoe UI,Frutiger,Frutiger Linotype,Dejavu Sans,Helvetica Neue,Arial,sans-serif;
  text-align: center;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

/*.eraseActive #canvas-figures g:hover { stroke-opacity: 20% !important; }*/

.contextMenu {
  text-align: center;
  box-shadow: 0 0 12px grey;
  position: absolute;
  left: 80%;
  pointer-events: all;
  width: 82px;
  height: auto;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  word-wrap: break-word;
}

.suggest-button {
  width: 80px;
  height: 80px;
  background-color: #fff;
  outline-style: none;
  padding: 2px;
}

.suggest-button:hover {
  background-color: rgba(0, 0, 0, .1);
}

